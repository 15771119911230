<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.item.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.item.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Item', 'Inserir')"
      :sem-filtro="!buscarPermissao('Item', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Item', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      row-group-mode="subheader"
      group-rows-by="categoria.nome"
      sort-mode="single"
      sort-field="categoria.nome"
      :sort-order="1"
      :expandable-row-groups="true"
      :expanded-row-groups="expandedRowGroups"
      :mostrar-seletor="buscarPermissao('Item', 'Deletar')"
      ajustar-altura-linha
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @paginar="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              v-if="buscarPermissao('Item', 'Editar')"
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="
                slotProps.data.categoria.nome == 'Peças' &&
                buscarPermissao('Item', 'Visualizar')
              "
              text
              color="secondary"
              @click="abrirVisualizarReserva(slotProps.data)"
            >
              {{ $t('modulos.item.acoes.visualizar_reserva') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>

      <template v-slot:agrupadorLinhas="{ slotProps }">
        <span
          >{{ slotProps.data.categoria.nome }} ({{
            totalItensAgrupados[slotProps.data.categoria.nome]
          }}
          {{
            totalItensAgrupados[slotProps.data.categoria.nome] > 1
              ? 'itens'
              : 'item'
          }})</span
        >
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ItemService from '@common/services/cadastros/ItemService';
import Filtro from './filtro.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.item.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'participanteFabricante.apelido',
            text: this.$t('modulos.item.tabela.fabricante'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.item.tabela.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      expandedRowGroups: [],
      totalItensAgrupados: {},
      filtroAplicado: { filter: '' },
      permissoes: [],
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Item', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.item.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    calcularTotalItens() {
      this.totalItensAgrupados = this.tabela.dados.reduce((acc, item) => {
        acc[item.categoria.nome] = (acc[item.categoria.nome] || 0) + 1;
        return acc;
      }, {});
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    expandTabela() {
      this.expandedRowGroups = [];
      this.tabela.dados.forEach((item) => {
        if (!this.expandedRowGroups.includes(item.categoria.nome))
          this.expandedRowGroups.push(item.categoria.nome);
      });
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ItemService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

          this.calcularTotalItens();
          this.expandTabela();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          ItemService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(this.$t(`modulos.item.exclusao_sucesso`));
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'item-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'item-editar',
        params: { id: item.id },
      });
    },
    abrirVisualizarReserva: function (item) {
      this.$router.push({
        name: 'item-visualizar-reserva',
        params: { id: item.id },
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
